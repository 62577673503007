'use strict';

angular.module('enervexSalesappApp').factory('LayoutUtil', function LayoutUtil(_, Util, LengthUtil) {
	return {
		getSection: function(GUIidentPath, layout, ID) {
			return _.find(layout, function(section) {
				if (ID) {
					return section.ID == ID
				} else {
					return section.GUIidentPath == GUIidentPath
				}
			})
		},
		getInputSections: function(GUIidentPath, layout, ID) {
			var section = this.getSection(GUIidentPath,layout, ID)
			return _.filter(layout, function(v) {
				return v.X2 == section.X1 && 
					v.Y2 == section.Y1 && 
					v.Z2 == section.Z1
			})
		},
		getOutputSection: function(GUIidentPath, layout, ID) {
			var section = this.getSection(GUIidentPath,layout, ID)
			var result = _.filter(layout, function(v) {
				return v.X1 == section.X2 && 
					v.Y1 == section.Y2 && 
					v.Z1 == section.Z2
			})
			return result && result.length > 0 && result[0]
		},
		getRun: function(startingSection, layout, runNumber) {
			var run = [startingSection]
			startingSection.runNumbers.push(runNumber)
			var outlet = this.getOutputSection(startingSection.GUIidentPath, layout,startingSection.ID)
			while(outlet) {
				outlet.runNumbers.push(runNumber)
				run.push(outlet)
				outlet = this.getOutputSection(outlet.GUIidentPath, layout, outlet.ID)
			}
			_.each(run, function(v){
				if (v.runNumbers.length > 1) {
					v.runClassification = "common"
				} else {
					v.runClassification = "isolated"
				}
			})
			return run
		},
		getRuns:function(layout) {
			var self = this
			_.each(layout, function(v){
				v.runNumbers = []
				delete v.runClassification
			})
			var appliances = this.getAppliances(layout)
			return _.map(appliances, function (appliance, index){
				var runNumber = index
				return self.getRun(appliance, layout, runNumber)
			})
		},
		getRunToFirstCommon:function(run) {
			var result = []
			_.each(run, function(v){
				result.push(v)
				if (v.runNumbers.length > 1) {
					return false
				}
			})
			return result
		},
		getRunUpTo:function(upto, run) {
			var result = []
			var found = false
			_.each(run, function(v){
				result.push(v)
				if (v == upto) {
					found = true
					return false
				}
			})
			return (found) ? result : null
		},
		getRunsUpTo:function(upto, runs) {
			var self = this
			var result = []
			_.each(runs, function(run){
				var runUpTo = self.getRunUpTo(upto, run)
				if (runUpTo) {
					result.push(runUpTo)
				}
			})
			return result
		},
		getAppliances:function(layout) {
			return  _.filter(layout, function(v){
				return v.Fit1 == "STP";
			})
		},
		totalUpTo:function(v, run) {
			var total = 0
			var place = _.each(run, function(o){
				if (v == o) {
					return false
				} else {
					total = total + o.NetLength
				}
			})
			return total
		},
		addToTotal: function(v, result) {
			result.any = result.any + v.NetLength;
			if (!v.DimZ) {
				result.horizontal = result.horizontal + v.DimX;
			} else {
				result.horizontal = result.horizontal + v.DimX + v.DimZ;
			}
			result.vertical = result.vertical + v.DimY;
		},
		totals:function(sections){
			var result = {
				any:0,
				horizontal:0,
				vertical:0
			}
			var self = this
			_.each(sections,function(v){
				self.addToTotal(v, result)
			})
			return result
		},
		runToString:function(run){
			return _.map(run, function(v){
				return v.GUIidentPath
			}).join(",")
		},
		addedProductsUpTo:function(upto, run, lines) {
			var mappedProducts = []
			_.each(run, function(v){
				_.each(lines, function(line){
					var section = _.find(line.sections, function(o){
						return o.section == v.GUIidentPath
					})
					if (section && line.product) {
						var mappedProduct = _.find(mappedProducts, function(mp){
							return idUtil.compareIds(mp.product._id, line.product._id)
						})
						if (mappedProduct) {
							mappedProduct.quantity = mappedProduct.quantity + section.quantity
							mappedProduct.sections.push(section)
						} else {
							mappedProducts.push({
								quantity: section.quantity,
								product: line.product,
								sections: [section]
							})
						}
					}
					return true
				})
				if (upto == v) {
					return false
				} else {
					return true
				}
			})
			return mappedProducts
		},
		runsContains: function(v, runs) {
			return _.filter(runs, function(run){
				var found = _.find(run, function(_v){
					return _v == v
				})
				return found ? true : false
			})
		},
		countProductSubtypesUpTo:function(upto, run, lines, subtypes) {
			var mappedProducts = this.addedProductsUpTo(upto, run, lines)
			var total =  0
			_.each(mappedProducts, function(mp){
				var existing = _.find(subtypes, function(subtype){
					return idUtil.compareIds(mp.product.subtype, subtype)
				})
				if (existing) {
					total = total + mp.quantity
				}
				return true
			})
			return total
		},
		countAllRunsProductSubtypesUpTo:function(upto, runs, lines, subtypes) {
			var self = this
			var runs = this.runsContains(upto, runs)
			var counts = _.map(runs, function (run){
				return self.countProductSubtypesUpTo(upto, run, lines, subtypes)
			})
			var result =  (counts.length > 0) ? _.max(counts) : 0
			return result
		},
		supportsApplianceCategories: function(layout, cats) {
			var appliances = this.getAppliances(layout)
			var doesntSupportCat = _.find(appliances, function(appliance){
				var cat = appliance.Appl.Cat
				var notsupported = _.find(cats, function(o){
					return o == cat
				})
				return notsupported ? true : false
			})
			return doesntSupportCat ? false : true
		},
		get3wayCover:function(v, design) {
			var application = design.application
			if (LengthUtil.getHorizontalLength(v, design) == 0  && v.DimY) {
				return application.stack.threeWayCoverVertical
			} else {
				return application.stack.threeWayCoverHorizontal
			}
		},
		allowsPureVertical:function(v, design){
			var application = design && design.application
			var Layout = design && design.fc5layout && design.fc5layout.Layout
			if (!Layout){
				return false
			} else if (!application){
				return false
			} else if (application.properties.pureVerticalStrategy == "always"){
				return true
			} if (!(application.properties.pureVerticalStrategy == "never")) {
				return false
			} else {
				var runs = this.getRuns(Layout)
				runs = this.getRunsUpTo(v, runs)
				var appliances = _.map(runs, function(run){
					return _.first(run)
				})
				var unsupportedAppliance = _.find(appliances, function(appliance){
					var cat = appliance.Appl.Cat;
					var unsupportedCat = _.find((application.properties.applianceCategories), function(o){
						return o == cat
					})
					return (unsupportedCat) ? true :false
				})
				return (unsupportedAppliance) ? false : true
			}

		},
		supportsApplianceCategories: function(layout, cats) {
			var appliances = this.getAppliances(layout)
			var doesntSupportCat = _.find(appliances, function(appliance){
				var cat = appliance.Appl.Cat
				var notsupported = _.find(cats, function(o){
					return o == cat
				})
				return notsupported ? true : false
			})
			return doesntSupportCat ? false : true
		}
	}
})
